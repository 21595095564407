import React from 'react';
import data from './ItemPortafolio.json'; // Asegúrate de que la ruta al archivo JSON sea correcta
import { Link } from 'react-router-dom';


const ItemCases = () => {
  return (
    <div className="row gy-3 g-md-3 hcf-isotope-grid">
      {data.map((item) => (

        <div className={item.grid}> 
          <a className="hcf-masonry-card rounded rounded-4 hoverImgCard" href={item.url} key={item.id} target="_blank">
              <img className="card-img img-fluid" loading="lazy" src={item.image} alt={item.title} />
              <div className="card-overlay d-flex flex-column justify-content-center bg-dark p-4">
                <h3 className="card-title text-white text-center mb-1">{item.title}</h3>
                <div className="card-category text-white text-center">{item.subtitle}</div>
              </div>
          </a>
        </div>
   
      ))}
    </div>
  );
};

export default ItemCases;