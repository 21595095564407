import React from 'react'
import { Link } from 'react-router-dom';


function Delivery() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Menú digital para pizzería</span>
                                    </h1>

                                    <div className="mb-5">
                                        <a href="https://www.behance.net/gallery/165920723/Menu-digital-para-despachos-a-domicilio" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a>
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <img src="../assets/imagen/cases/delivery/delivery0.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <p className="lead mb-4 text-grey">
                                        Este proyecto fue desarrollado para una <span className="text-violete fw-bold">Pizzería Italiana</span>, con el objetivo de crear un sistema de entrega eficiente dentro de la ciudad.
                                    </p>   
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-5">El proyecto inicia con un <span className="fw-bold text-white">Design Sprint</span> para la creación de un MVP para a futuro, ver viabilidad de agregar nuevas características y funcionalidades.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Objetivos del Proyecto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                            <li className="text-grey fs-5 mb-4">Crear un <span className="fw-bold text-white">MVP funcional</span> para el sistema de entrega de la pizzería.</li>
                                            <li className="text-grey fs-5 mb-4">Diseñar una <span className="fw-bold text-white">aplicación intuitiva para facilitar pedidos y entregas</span> a domicilio.</li>
                                            <li className="text-grey fs-5 mb-4">Planificar la <span className="fw-bold text-white">expansión futura</span> de la aplicación para incluir pedidos en el local.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/delivery/delivery1.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

          
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Proceso de Design Sprint
                                        </p>
                                    </div>


                                    <div className="col-sm-12 col-lg-7">
                                            <p className="lead text-white fw-bold">Día 1: Entender, definir y divergir</p>
                                            <ul className="mb-6">
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Contexto del proyecto:</span> Revisión de los objetivos del proyecto y las necesidades del negocio.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Investigación y empatía:</span> Discusión sobre las necesidades de los usuarios.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Definición del problema:</span> Identificar y priorizar los problemas clave a resolver.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Brainstorming:</span> Sesión de generación de ideas para abordar los problemas identificados.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Selección de ideas:</span> Votación y selección de las ideas más prometedoras.</li>
                                                {/* <img src="../assets/imagen/cases/delivery/dia1-01.png" alt="" className="hoverEffect bg-img w-100 mb-4"/> */}
                                            </ul>

                                            <p className="lead text-white fw-bold">Día 2: Decidir y prototipar</p>
                                            <ul className="mb-6">
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Storyboarding</span>: Desarrollar storyboards y flujos de usuario.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Decisión final:</span> Elegir la ideas a prototipar por un Crazy 8's.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Desarrollo de prototipos:</span> Crear un prototipo funcional de baja fidelidad.</li>
                                                <img src="../assets/imagen/cases/delivery/dia2-01.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                                <img src="../assets/imagen/cases/delivery/dia2-02.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                                <img src="../assets/imagen/cases/delivery/dia2-03.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                            </ul>

                                            <p className="lead text-white fw-bold">Día 3: Probar y aprender</p>
                                            <ul className="mb-6">
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Testeo del prototipo:</span> Realizar pruebas de usabilidad con usuarios reales.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Recopilación de feedback:</span> Observar y registrar las reacciones y sugerencias.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Análisis de resultados:</span> Revisar y analizar el feedback y los datos recogidos.</li>
                                                <li className="text-grey fs-5 mb-4"><span className="fw-bold">Planificación de pasos futuros:</span> Decidir los siguientes pasos basándose en el feedback.</li>
                                                {/* <img src="../assets/imagen/cases/delivery/dia3-01.png" alt="" className="hoverEffect bg-img w-100 mb-4"/> */}
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/delivery/delivery2.gif" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/delivery/delivery3.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/delivery/delivery4.gif" alt="" className="animate-100 mb-4"/>


                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/allergies" target="_self" className="button-violete">← Plataforma de alergias</Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/recruitment" target="_self" className="button-violete">Plataforma de reclutamiento →</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


            </main>
        </div>
    )
}
export default Delivery