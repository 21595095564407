import React from 'react'
import { Link } from 'react-router-dom';

function Header() {
    return (  
        <div>
            <main className="flex-shrink-0">
                {/* <nav className="navbar navbar-expand-lg navbar-dark py-5"> */}

               
{/* 
                <nav className="navbar-expand-lg navbar-dark fixed-top" id="mainNav">

                    <div className="container-fluid px-0">
                        <Link className="navbar-brand" to="/">
                            <span className="fw-bolder text-primary">
                                <img src="assets/imagen/logotipo.svg" alt=""/>
                            </span>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Inicio</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/estudios">Estudios</Link>
                                </li>

                                <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Casos de estudio
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="delivery"className="dropdown-item">Menu digital para Pizzería</Link>
                                    </li>
                                    <li>
                                        <Link to="/recruitment" className="dropdown-item">Plataforma de reclutamiento</Link>
                                    </li> 
                                    <li> 
                                        <Link to="/events" className="dropdown-item">Interface de eventos</Link>
                                    </li>
                                    <li>
                                        <Link to="/cyclists"className="dropdown-item">Comunidad de ciclismo</Link>
                                    </li>
                                    <li>
                                        <Link to="/visualizer" className="dropdown-item">Visualización Covid-19</Link>
                                    </li> 
                                    <li> 
                                        <Link to="/allergies" className="dropdown-item">Plataforma de alergias</Link>
                                    </li>
                                </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav> */}






                <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                    <div className="container">
                       
                        <Link className="navbar-brand" to="/">
                            <span className="fw-bolder text-primary">
                                <img src="assets/imagen/logotipo.svg" alt=""/>
                            </span>
                        </Link>
                        
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                          
                            <img src="assets/imagen/icons/menu.svg" alt="" className="wicon-60"/>  
                            <br/>
                            Menú
                        
                        </button>
                        <div className="navbar-collapse collapse" id="navbarResponsive">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/">Inicio</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link className="nav-link" to="/estudios">Estudios</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/metodologia">Metodología</Link>
                                </li>

                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/entornos">Entornos</Link>
                                </li>
                                 */}


                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/casos">Casos de estudio</Link>
                                </li> */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Casos de estudio
                                    </a>
                                    <ul className="dropdown-menu menuImg">
                                        <li>
                                            <Link to="logistic"className="dropdown-item">
                                                <img src="../assets/imagen/menu/logistic.png" alt="/" className="w-20"/> RAG en Logística</Link>
                                        </li>





                                        <li>
                                            <Link to="delivery"className="dropdown-item">
                                                <img src="../assets/imagen/menu/delivery.png" alt="/" className="w-20"/> Menu digital para Pizzería</Link>
                                        </li>
                                        <li>
                                            <Link to="/recruitment" className="dropdown-item">
                                                <img src="../assets/imagen/menu/recruitment.png" alt="/" className="w-20"/> Plataforma de reclutamiento</Link>
                                        </li> 
                                        <li> 
                                            <Link to="/events" className="dropdown-item">
                                                <img src="../assets/imagen/menu/events.png" alt="/" className="w-20"/> Interface de eventos</Link>
                                        </li>
                                        <li>
                                            <Link to="/cyclists"className="dropdown-item">
                                                <img src="../assets/imagen/menu/cyclists.png" alt="/" className="w-20"/> Comunidad de ciclismo</Link>
                                        </li>
                                        <li>
                                            <Link to="/visualizer" className="dropdown-item">
                                                <img src="../assets/imagen/menu/visualizer.png" alt="/" className="w-20"/> Visualización Covid-19</Link>
                                        </li> 
                                        <li> 
                                            <Link to="/allergies" className="dropdown-item">
                                                <img src="../assets/imagen/menu/allergies.png" alt="/" className="w-20"/> Plataforma de alergias</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/portafolio">Portafolio</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>




           </main>
        </div>
    )
}
export default Header