import React from 'react';
import data from './ItemMethodology.json'; // Asegúrate de que la ruta al archivo JSON sea correcta
// import { Link } from 'react-router-dom';


const ItemMethodology = () => {
  return (
    <div className="col-xxl-8">
      {data.map((item) => (

        <div className="row gx-5" key={item.id}>
          <div className="col-lg-2 col-md-3  col-xs-2mb-4 text-center">
            <img src={item.image} alt={item.title} className=""/>
          </div>

          <div className="col-lg-10 col-md-9 col-xs-10 mb-4">
            <div className="itemCard itemCard-bg">
                <p className="lead fw-bold">{item.title}</p>
                <p className="text-grey">{item.description}</p>
            </div>
          </div>
        </div> 
      ))}
    </div>
  );
};

export default ItemMethodology;