import React from 'react'
import { Link } from 'react-router-dom';


function Visualizer() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1 wow animate__animated animate__fadeIn">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Visualizador Covid-19</span>
                                    </h1>

                                    <div className="mb-5">
                                        <a href="https://www.behance.net/gallery/165894227/Visualizador-de-comunas-para-Chile-por-Covid-19" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a>
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/visualizer/visualizer0.png" alt="" className="hoverEffect w-100 mb-4"/>
                                   
                                    <p className="lead mb-4 text-grey">
                                        Este proyecto independiente fue realizado para la Start-ups <span className="text-violete fw-bold">Rukma.Digital</span> con el objetivo de democratizar el acceso a la información oficial proporcionada por el Gobierno de Chile. 
                                    </p>  
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-5">El proyecto se enfocó en entregar <span className="fw-bold text-white">información de manera oportuna y accesible</span>, especialmente para <span className="fw-bold text-white">adultos mayores y usuarios</span> con limitados conocimientos tecnológicos.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Objetivos del Proyecto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Facilitar el acceso a la información</span> oficial de manera constante y sencilla.</li>
                                            <li className="text-grey fs-5 mb-4">Asegurar la <span className="fw-bold text-white">accesibilidad de la información</span>  para usuarios con diversas capacidades tecnológicas.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Contribuir positivamente en la sociedad</span>  durante tiempos difíciles como la pandemia.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/visualizer/visualizer2.png" alt="" className="hoverEffect w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

          
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Proceso de desarrollo
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">

                                        <p className="lead text-white fw-bold">Investigación y validación de hipótesis</p>

                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Estudio de usuarios:</span> Análisis de las necesidades de los usuarios, especialmente de adultos mayores y aquellos con menos habilidades tecnológicas.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Validación del MVP:</span> Pruebas y ajustes del producto mínimo viable basados en feedback real.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Diseño y desarrollo</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Diseño UX:</span> Creación de flujos de usuario y diseños de interfaz centrados en la accesibilidad y facilidad de uso.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Desarrollo ágil:</span> Implementación del proyecto en sprints, utilizando metodologías ágiles y DevOps.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Lanzamiento y monitoreo</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Implementación:</span> Despliegue de la solución con un enfoque en la accesibilidad y la comprensión del usuario.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Feedback continuo:</span> Recolección y análisis de comentarios de usuarios para mejoras iterativas.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

 



                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/visualizer/visualizer1.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/visualizer/visualizer3.gif" alt="" className="hoverEffect w-100 mb-4 bg-img"/>
                                    <img src="../assets/imagen/cases/visualizer/visualizer4.gif" alt="" className="animate-100 mb-4"/>

                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/cyclists" target="_self" className="button-violete">← Comunidad de ciclismo</Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/allergies" target="_self" className="button-violete">Plataforma de alergias →</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>



            </main>
        </div>
    )
}
export default Visualizer