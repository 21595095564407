import React from 'react'
import { Link } from 'react-router-dom';


function Entornos() {
    return (  
        <div>
             <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Sobre mi
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Habilidades para entornos digitales</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center ">
                            <div className="col-xxl-12">
                                <div className="row text-start my-5 align-items-center">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-white">
                                            <img src="assets/imagen/skills/sk1.svg" alt="" className="wicon-100 mb-3 hoverEffect"/> Experiencias de usuario
                                        </span>
                                    </h2>
                                    <p className="lead mb-4 text-grey ms-5">
                                        Entender, descubrir y mejorar la forma en que los usuarios interactúan con sus experiencias digitales.
                                    </p>                             
                                </div>
                            </div>
                        </div>

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-8">
                                <div className="row gx-5">

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                    
                                        {/* 
                                            <p className="fs-5 text-grey">
                                                Entender, descubrir y mejorar la forma en que los usuarios interactúan con sus experiencias digitales.
                                            </p> */}

                                            <p className="fs-5 text-grey">
                                                La creación de experiencias de usuario excepcionales es fundamental en el proceso de diseño digital.
                                            </p>
                                            <p className="fs-5 text-grey">
                                                Un Consultor UX y Product Designer se centra en comprender profundamente las necesidades y comportamientos de los usuarios para diseñar productos intuitivos y accesibles.
                                            </p>
                                            <p className="fs-5 text-grey">
                                                A través de un enfoque empático y centrado en el usuario, estos profesionales aseguran que cada punto de contacto sea una oportunidad para satisfacer y superar las expectativas del usuario, fomentando así la lealtad y el compromiso.
                                            </p>
                                        
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">

                                        <p className="fs-5 fw-semibold text-white"><span className="text-violete">➜</span> Investigación de usuarios</p>
                                        <p className="fs-6 text-grey">Capacidad para realizar y analizar investigaciones cualitativas y cuantitativas para entender las necesidades, deseos y comportamientos de los usuarios. Esto incluye técnicas como entrevistas, encuestas y pruebas de usabilidad.</p>
                                            <hr/>
                                        <p className="fs-5 fw-semibold text-white"><span className="text-violete">➜</span> Diseño centrado en el usuario</p>
                                        <p className="fs-6 text-grey">Desarrollo de soluciones de diseño basadas en las necesidades reales de los usuarios, aplicando principios de usabilidad y accesibilidad para crear productos que sean fáciles de usar y entender.</p>
                                            <hr/>
                                        <p className="fs-5 fw-semibold text-white"><span className="text-violete">➜</span> Prototipado y pruebas iterativas</p>
                                        <p className="fs-6 text-grey">Creación rápida de prototipos de baja y alta fidelidad para testear ideas con usuarios reales, permitiendo iteraciones rápidas basadas en feedback directo.</p>
                                            <hr/>
                                        <p className="fs-5 fw-semibold text-white"><span className="text-violete">➜</span> Diseño de interacción</p>
                                        <p className="fs-6 text-grey">Habilidad para diseñar flujos de interacción y transiciones que resulten naturales y mejoren la experiencia del usuario, asegurando que el producto final sea tanto funcional como atractivo.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
     

                   
            </main>
        </div>
    )
}
export default Entornos