import React from 'react'
import { Link } from 'react-router-dom';


function Cyclists() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Comunidad de ciclismo</span>
                                    </h1>

                                    <div className="mb-5">
                                        <a href="https://www.behance.net/gallery/165978309/Comunidad-de-ciclismo-en-Santiago-de-Chile" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a>
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/cyclists/cyclists0.png" alt="" className="hoverEffect w-100 mb-4"/>
                                </div>
                                <p className="lead mb-4 text-grey">
                                        Este producto digital fue realizado como <span className="text-violete fw-bold"> Consultor UX y Diseñador UI</span>, con el objetivo de crear una aplicación que ofrezca un ambiente seguro e informativo para ciclistas urbanos. 
                                </p>   
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul className="text-grey fs-5 mb-5">
                                            <li className="text-grey fs-5 mb-4">La necesidad surgió de la <span className="fw-bold text-white">ausencia de una comunidad formal</span> para los amantes de la bicicleta.</li>
                                            <li className="text-grey fs-5 mb-4">La falta de datos seguros para la <span className="fw-bold text-white">creación y seguimiento</span> de rutas.</li>
                                            <li className="text-grey fs-5 mb-4">La necesidad de un <span className="fw-bold text-white">grupo de seguridad</span> para la práctica de ciclismo, tanto grupal como individual.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                        Objetivos del Proyecto

                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Desarrollar el UI</span> una plataforma comunitaria para ciclistas.</li>
                                            <li className="text-grey fs-5 mb-4">Proveer <span className="fw-bold text-white">datos confiables y actualizados</span> para la creación y seguimiento de rutas.</li>
                                            <li className="text-grey fs-5 mb-4">Facilitar un <span className="fw-bold text-white">sistema de soporte y seguridad</span> para ciclistas.</li>
                                        </ul>
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/cyclists/cyclists2.png" alt="" className="hoverEffect w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

          
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Proceso de desarrollo
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="lead text-white fw-bold">Investigación y análisis</p>
                                        <ul className="mb-6">
                                            <li  className="text-grey fs-5 mb-4"><span className="fw-bold">Entrevistas:</span> Realización de estudios para comprender las necesidades específicas de los ciclistas.</li>
                                            <li  className="text-grey fs-5 mb-4"><span className="fw-bold">Benchmark:</span> Evaluación de aplicaciones similares para identificar oportunidades de mejora y diferenciación.</li>
                                        </ul>
                                        <p className="lead text-white fw-bold">Diseño de interfaz y UX</p>
                                        <ul className="mb-6">
                                            <li  className="text-grey fs-5 mb-4"><span className="fw-bold">Wireframes y prototipos:</span> Creación de diseños preliminares para establecer la estructura y el flujo de la aplicación.</li>
                                            <li  className="text-grey fs-5 mb-4"><span className="fw-bold">Diseño visual y UI:</span> Desarrollo de un diseño atractivo y funcional que mejora la experiencia del usuario.</li>
                                        </ul>
                                        <p className="lead text-white fw-bold">Entregables</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Prototipo funcional en Figma:</span> La aplicación se entrega en un enlace funcional para la evaluación de equipos de desarrollo.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/cyclists/cyclists1.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/cyclists/cyclists3.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/cyclists/cyclists4.gif" alt="" className="animate-100 mb-4"/>

                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/events" target="_self" className="button-violete">← Interface de eventos</Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/visualizer" target="_self" className="button-violete">Visualización Covid-19 →</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


            </main>
        </div>
    )
}
export default Cyclists