import React from 'react'
import { Link } from 'react-router-dom';


function Allergies() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1 wow animate__animated animate__fadeIn">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Plataforma para alergias</span>
                                    </h1>
                                    <div className="mb-5">
                                        <a href="https://www.behance.net/gallery/165894227/Visualizador-de-comunas-para-Chile-por-Covid-19" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className="wicon-20 mr-6 mb-1"/> 
                                        </a>
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/allergies/allergies0.png" alt="" className="hoverEffect w-100 mb-4"/>                                   
                                </div>
                                <p className="lead mb-4 text-grey">
                                    Este producto digital fue desarrollado como parte del <span className="text-violete fw-bold">Diplomado en UX Design</span> en la <span className="text-violete fw-bold">Universidad Finis Terrae</span> en la entrega final el año 2019. 
                                </p>  
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-5">Se enfocó en la creación de una aplicación destinada a formar una <span className="fw-bold text-white">comunidad para personas con alergias o intolerancias alimentarias</span>, abordando un área poco explorada en el mundo del delivery.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Objetivos del Proyecto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                             <li className="text-grey fs-5 mb-4">Crear una <span className="fw-bold text-white">plataforma comunitaria</span> para personas con restricciones alimentarias.</li>
                                             <li className="text-grey fs-5 mb-4">Proporcionar <span className="fw-bold text-white">información confiable</span> y de primera mano sobre alimentos seguros.</li>
                                             <li className="text-grey fs-5 mb-4">Mejorar la <span className="fw-bold text-white">accesibilidad a opciones de alimentos</span> para este grupo específico de usuarios.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/allergies/allergies5.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/allergies/allergies6.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/allergies/allergies7.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/allergies/allergies8.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/allergies/allergies9.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

          
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Proceso de desarrollo
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="lead text-white fw-bold">Empatía y definición de la problemática</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Empatía con los usuarios:</span> Uso de herramientas UX para entender profundamente las necesidades y desafíos de las personas con alergias o intolerancias alimentarias.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Definición del problema:</span> Identificación clara de la necesidad de mejores opciones de alimentos para este grupo.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Investigación y Desarrollo</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Investigación de usuarios:</span> Estudios y análisis de las preferencias y necesidades de las personas con restricciones alimentarias.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Desarrollo de Wireframes y Prototipos:</span> Creación de diseños preliminares y prototipos funcionales para estructurar la aplicación.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Pruebas con Usuarios y Refinamiento</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Testeo con usuarios reales:</span> Realización de pruebas de usabilidad para recoger feedback directo de los usuarios.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Iteraciones de diseño:</span> Ajustes y mejoras del diseño basados en los resultados de las pruebas.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/allergies/allergies2.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/allergies/allergies3.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/allergies/allergies4.gif" alt="" className="animate-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/visualizer" target="_self" className="button-violete">← Visualización Covid-19</Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/logistic" target="_self" className="button-violete">Proceso RAG en Logística →</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


            </main>
        </div>
    )
}
export default Allergies