import React from 'react'
import { Link } from 'react-router-dom';


function Recruitment() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1 wow animate__animated animate__fadeIn">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Plataforma de reclutamiento</span>
                                    </h1>
                                    <div className="mb-5">
                                        <a href="https://www.behance.net/gallery/165977975/Reclutamiento-Post-Pandemia" target="_blank" rel="noreferrer" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a>
                                        {/* <a href="#" target="_blank" rel="noopener" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */} 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>


                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-star my-5">
                                    <img src="../assets/imagen/cases/recruitment/recruitment0.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <p className="lead mb-4 text-grey">
                                        Este es un proyecto desarrollado para el <span className="text-violete fw-bold"> Diplomado Diseño basado en datos</span> realizados entre julio 2022 hasta enero 2023 en la <span className="text-violete fw-bold"> Universidad del Desarrollo</span>.
                                    </p> 
                                </div>
                                <a href="../assets/document/informe-reclutamiento-post-pandemia-2023.pdf" target="_blank" rel="noopener" className="document">
                                    <div className="text-center my-2 p-4">
                                        <img src="../assets/imagen/icons/download.svg" alt="" className="hoverEffect w-20 mb-4"/>
                                        <p className="fs-5 fw-bold mb-2 text-grey"> Puedes visualizar y descargar la Investigación Reclutamiento Post Pandemia de manera gratuita en caso de necesitarlo para otra investigación</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-4">El tema seleccionado para trabajar fue la <span className="fw-bold text-white">identificación de cómo afectó la pandemia dentro del proceso de reclutamiento TI</span>.</p>
                                        <p className="text-grey fs-5 mb-4">Cómo sabemos la mayoría de los talentos en TI trabajan de manera  <span className="fw-bold text-white">híbrida o 100% remota</span> pero algunas empresas aún requieren de talento presencial.</p>

                                        <ul className="text-grey fs-5 mb-5">
                                            <li className="text-grey fs-5 mb-4">¿Por qué existen estas variedades?</li>
                                            <li className="text-grey fs-5 mb-4">¿Cómo el reclutamiento debe adaptarse a las nuevas necesidades de los talentos?</li>
                                            <li className="text-grey fs-5 mb-4">¿Cómo incentivar a los talentos y disminuir la rotación?</li>
                                        </ul>
                                        <p className="text-white fs-5 mb-4">Son problemáticas que se quisieron abordar dentro de este proyecto.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/recruitment/recruitment7.png" alt="" className="hoverEffect w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Un proyecto basado en datos
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-4">El proceso desarrollado en este proyectos, <span className="fw-bold text-white">consistió en 4 temas</span>, estos son:</p>
                                        <ul>
                                            <li className="text-grey fs-5 mb-4">Introducción a la investigación</li>
                                            <li className="text-grey fs-5 mb-4">Diseño de investigación</li>
                                            <li className="text-grey fs-5 mb-4">Ejecución y análisis de investigación</li>
                                            <li className="text-grey fs-5 mb-4">Solución al problema de investigación</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                


          
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                        Proceso de Investigación Basado en Datos
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">

                                        <p className="lead text-white fw-bold">Introducción a la investigación</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Definición del tema:</span> Establecimiento del enfoque del proyecto en la intersección de la pandemia y el reclutamiento TI.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Revisión de literatura:</span> Recopilación de información existente sobre el reclutamiento en TI y los efectos de la pandemia en el trabajo.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Diseño de Investigación</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Metodología:</span> Desarrollo de una metodología de investigación para recopilar y analizar datos relevantes.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Instrumentos de recolección de datos:</span> Creación de encuestas, entrevistas y otras herramientas para obtener información.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Ejecución y análisis de investigación</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Recolección de datos:</span> Implementación de la metodología y recopilación de datos.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Análisis de datos:</span> Evaluación y análisis de los datos recopilados para identificar tendencias y patrones.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Solución al problema de investigación</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Desarrollo de soluciones:</span> Propuesta de estrategias basadas en los hallazgos para mejorar el reclutamiento y retención de talentos en TI.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Recomendaciones:</span> Presentación de recomendaciones a empresas y organizaciones sobre cómo adaptarse a las nuevas dinámicas laborales.</li>
                                        </ul>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/recruitment/recruitment1.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/recruitment/recruitment2.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/recruitment/recruitment3.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/recruitment/recruitment4.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/recruitment/recruitment5.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/recruitment/recruitment6.gif" alt="" className="bg-img w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/logistic" target="_self" className="button-violete">← Proceso RAG en Logística</Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/events" target="_self" className="button-violete">Plataforma de eventos →</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


            </main>
        </div>
    )
}
export default Recruitment