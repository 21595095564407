import React from 'react';
import data from './ItemCases.json'; // Asegúrate de que la ruta al archivo JSON sea correcta
import { Link } from 'react-router-dom';


const ItemCases = () => {
  return (
    <div className="row gy-5 g-md-5">
      {data.map((item) => (

          <div className="col-lg-6 col-xs-12 " key={item.id}>
            <Link to={item.url} target="_self" className="grid ">
                <figure className="effect-lily">
                    <img src={item.image} alt={item.title} />
                    <figcaption>
                        <div>
                          <h4 className="fs-2 fw-bold">{item.title}</h4>
                          <p className="fs-4 text-grey m-1">{item.year}</p>
                        </div>
                        <a href="#" className="hoverImgCard">View more</a>
                    </figcaption>			
                </figure>
            </Link>
          </div>
        
      ))}
    </div>
  );
};

export default ItemCases;