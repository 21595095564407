import React from 'react'
import { Link } from 'react-router-dom';


function Estudios() {
    return (  
        <div>
             <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-10">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Sobre mi
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Estudios</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <article className="py-5">
                    <div className="container py-5">
                        {/* <div className="row gx-5 justify-content-start">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-green">Advance</span>
                                    </h2>
                                    <p className="lead mb-4 text-grey">
                                        Casos de estudio de proceso metodológico de UX aplicados a diferentes proyectos y rubros
                                    </p>                             
                                </div>
                            </div>
                        </div> */}
{/* 
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-green">Master</span>
                                    </h2>                           
                                </div>
                            </div>
                        </div> */}

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">  

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <p className="text-bg-dark text-white fs-5">
                                            Dominio a nivel profesional de las herramientas que están transformando la <span className="text-violete"> Inteligencia Artificial</span>, certificado por <span className="text-violete">Founderz y Microsoft</span>. <br/><br/> Uso de herramientas de IA Generativa como <span className="text-violete">ChatGPT, Bing Chat, Midjourney, Copilot</span>. 
                                        </p>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Máster Online IA e Innovación <span className="labelProgreso">En progreso</span></p>
                                                <p className="text-grey">2024</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st12.png" alt="" className="logoStudie"/>
                                                <img src="assets/imagen/studies/st13.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> 

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-green">Advance</span>
                                    </h2>                           
                                </div>
                            </div>
                        </div>

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Programa Avanzado en CRO, UX & Analytics</p>
                                                <p className="text-grey">2021</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st1.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <p className="text-bg-dark text-white fs-5">
                                            El Programa <span className="text-violete">Avanzado en CRO, UX & Analytics</span> forma como experto en diseño e implementación de una estrategia  <span className="text-violete">CRO</span>, orientada a mejorar el porcentaje de conversión a lo largo de todo el funnel.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
           
                <article className="py-5">
                    <div className="container py-5">

                            <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                                <div className="col-xxl-10">
                                    <div className="text-start my-5">
                                        <h2 className="display-5 fw-semibold">
                                            <span className="text-green">Certificaciones</span>
                                        </h2>                           
                                    </div>
                                </div>
                            </div>


                            <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <img src="assets/imagen/studies/headerA.png" alt="" className="itemCard"/>
                                        <div href="#" target="_blank" className="itemCard itemCard-bg itemCard-b-top">
                                            <div>
                                                <p className="lead fw-bold">Certificación Profesional de Diseño UX de Google <span className="labelProgreso">En progreso</span></p> 
                                                <p className="text-grey">2024</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st2.png" alt="" className="logoStudie"/>
                                                <img src="assets/imagen/studies/st3.png" alt="" className="logoStudie"/>

                                            </div>    
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <img src="assets/imagen/studies/headerB.png" alt="" className="itemCard"/>
                                        <div href="#" target="_blank" className="itemCard itemCard-bg itemCard-b-top">
                                            <div>
                                                <p className="lead fw-bold">Certificación Internacional UX-PM 3 Niveles</p>
                                                <p className="text-grey">2018</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st4.png" alt="" className="logoStudie"/>
                                                <img src="assets/imagen/studies/st5.png" alt="" className="logoStudie"/>

                                            </div>    
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </article>

                <article className="py-5">
                    <div className="container py-5">

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-green">Diplomados</span>
                                    </h2>                           
                                </div>
                            </div>
                        </div>



                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diplomado Diseño Basado en Datos</p>
                                                <p className="text-grey">2022</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st6.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diplomado ResearchOps</p>
                                                <p className="text-grey">2020</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st7.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diplomado Discovery UX</p>
                                                <p className="text-grey">2020</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st7.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diplomado UX Design</p>
                                                <p className="text-grey">2019</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st8.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diplomado Gestión y Desarrollo de Proyectos Digitales</p>
                                                <p className="text-grey">2014</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st9.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diseño Gráfico</p>
                                                <p className="text-grey">2010</p>
                                                <div className="mb-2"><br/></div>
                                                <img src="assets/imagen/studies/st10.png" alt="" className="logoStudie"/>
                                            </div>    
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </article>

                <article className="py-5">
                    <div className="container py-5">


                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-green">Bootcamp & Cursos</span>
                                    </h2>                           
                                </div>
                            </div>
                        </div>



                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Curso Investigación UX con IA</p>
                                                <p className="text-grey">2024</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> AyerViernes</p>
                                            </div>    
                                        </div>
                                    </div>



                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Diseño UX Essentials</p>
                                                <p className="text-grey">2023</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> Escuela de Postgrado y Edu. Continua FCFM</p>
                                            </div>    
                                        </div>
                                    </div>


                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Curso CRO</p>
                                                <p className="text-grey">2022</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> Conviértete & Webpositer Academy</p>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Curso Product Design</p>
                                                <p className="text-grey">2021</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> Sol Mesz</p>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Bootcamp UX Writing</p>
                                                <p className="text-grey">2021</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> DesignCore</p>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">CX: Customer Experience</p>
                                                <p className="text-grey">2020</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> MEDU Escuela de Innovación</p>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Machine Learning en el contexto UX</p>
                                                <p className="text-grey">2020</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> Aprende UX</p>
                                            </div>    
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xs-12 mb-4">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <p className="lead fw-bold">Arquitectura de Información</p>
                                                <p className="text-grey">2019</p>
                                                <div className="mb-2"><br/></div>
                                                <p className="text-violete fs-6 fw-bold">
                                                <img src="assets/imagen/icons/studie.svg" alt="" className="wicon-30 mb-2"/> Universidad de Chile</p>
                                            </div>    
                                        </div>
                                    </div>







                                </div>
                            </div>
                        </div>

                    </div>
                </article>            
            </main>
        </div>
    )
}
export default Estudios