import React from 'react'
// import { Link } from 'react-router-dom';

import ItemCases from './casos/ItemCases/ItemCases.js';


function Inicio() {
    return (  
        <div>
                  
            <main className="flex-shrink-0">
                <header className="py-1 header-center">
                    <div className="container px-1">
                        <div className="row mt-20 gx-1 align-items-center wow animate__animated animate__fadeInDown animate__delay-0s">
                            <div className="col-xxl-12">
                                <div className="text-center">
                                    <img src="assets/imagen/icons/fcophox.png" alt="fcophox" className="avatar wow animate__animated animate__fadeIn animate__delay-1s"/>
                                    <h1 className="display-0 text-white mb-5 wow animate__animated animate__fadeIn animate__delay-1s">
                                        <span className="text-green">Optimización <br/> </span>de experiencias
                                        {/* <span className="text-green">Diseña de </span> Productos desde el UX */}

                                    </h1>
                                    <p className="lead text-grey fw-semibold fs-4  wow animate__animated animate__fadeInDown  animate__delay-2s">
                                        Senior Product Designer
                                        <span className="text-grey"> • </span>
                                        Consultor UX
                                        <span className="text-grey"> • </span>
                                        Analista CRO
                                    </p>
                                    <p className="text-violete fs-6 fw-semibold  wow animate__animated animate__fadeInDown  animate__delay-3s">
                                        <img src="assets/imagen/icons/place.svg" alt="" className="wicon-30 mb-2"/>
                                        Trabajando remoto desde Santiago de Chile
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </main>
            


            <article className="py-5" id="Casos">
                <div className="container py-5">
                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                        <div className="col-xxl-10">
                            <div className="text-center my-5">
                                <h2 className="display-5 fw-semibold">
                                    <span className="text-white">Experiencia en productos digitales</span>
                                </h2>
                                <p className="lead mb-4 text-grey">
                                    Casos de estudio de proceso metodológico de UX aplicados a diferentes proyectos y rubros
                                </p>                             
                            </div>
                        </div>
                    </div>
                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                        <div className="col-xxl-9">                    
                            <ItemCases/>
                        </div>
                    </div>
                </div>
            </article>



            <article className="py-5">
                <div className="container py-5">
                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                        <div className="col-xxl-10">
                            <div className="text-center my-5">
                                <h2 className="display-5 fw-semibold">
                                    <span className="text-white">Diseño de productos desde UX</span>
                                </h2>
                                <p className="lead mb-4 text-grey ">Con más de 12 años dedicados a la gestión y desarrollo de proyectos digitales.</p>                             
                            </div>
                        </div>
                    </div>

                 
                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">


                        <div className="col-xxl-10">
                            <div className="row gx-5">

                                <div className="col-xs-12 col-lg-4">
                                    <div className="grid">
                                        <figure className="effect-lily gridspecial">
                                            <img src="./assets/imagen/profile.png" alt="fcophox"/>
                                            <figcaption>
                                                <div>
                                                {/* <h5 className="fs-5 fw-light text-grey">@fcophox</h5> */}
                                                </div>
                                                <a href="#">View more</a>
                                            </figcaption>			
                                        </figure>
                                    </div>
                                </div>
                        
                                <div className="col-xs-12 col-lg-8">
                                    <div className="itemCard-bg">
                                        <div className="boxCertification align-items-center">
                                            <div className="certification align-items-center">
                                                <img src="assets/imagen/icons/certification.svg" alt="" className="iconCertification"/>
                                            </div>
                                            <div className="information align-items-center">
                                                <p className="text-violete fw-bold small  align-items-center justify-content-center mb-0">Certificado en Experiencia de usuario en la UX Alliance por Ayer Viernes Chile.</p>
                                            </div>
                                        </div>
                                        <div className="mb-5"></div>
                                        <div className="mb-5"></div>
                                        <p className="text-bg-dark fs-4 fw-bold">
                                            Consultor en optimización de experiencia de usuario y usabilidad para productos digitales.
                                        </p>
        
                                        <p className="text-grey  fs-5">
                                            Investigando y descubriendo la visión del <span className="text-violete">negocio</span>, la realidad del <span className="text-violete">mercado</span> y los voz de los <span className="text-violete">usuario</span>.
                                        </p>
                                        <div className="mb-5"></div>
                                            <a href="https://www.linkedin.com/in/fcophox/" target="_blank"  rel="noreferrer" className="button-green">Visita mi Linkedin <span className="arrowB">→</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </article>


            <article className="py-5 ">
                <div className="container py-5">
                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp animate__delay-0s">
                        <div className="col-xxl-10">
                            {/* <div className="text-start my-5">
                                <img src="/assets/imagen/poster 1.png" alt="" className="hoverEffect w-100"/>
                                <p className="display-4 text-bg-dark mb-4 fw-light ">
                                    Conoce la <span className="text-violete">nueva visión y valores de UX</span> — una nueva versión profesional
                                </p>  
                            </div> */}
                            <div className="text-start my-5">
                               
                                
                                <a href="https://medium.com/@fcophox/la-importancia-de-un-portafolio-ux-en-2024-una-reflexi%C3%B3n-para-profesionales-de-ux-y-dise%C3%B1adores-de-673f2d64ad56" target="_blank" className="hoverImgCard"  rel="noreferrer">
                                    <img src="assets/imagen/poster.png" alt="" className="hoverEffect w-100 poster"/>
                                    <p className="display-4 text-bg-dark mb-4 fw-light ">
                                    <span className="text-violete">
                                    La importancia de un Portafolio UX en 2024</span> — Una reflexión para profesionales de UX y Diseñadores de productos
                                    </p>  
                                    <p className="button-green">Leer en Medium <span className="arrowB">→</span></p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </article>



            <article className="py-5">
                <div className="container py-5">
                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                        <div className="col-xxl-10">
                            <div className="text-center my-5">
                                <h2 className="display-5 fw-semibold">
                                    <span className="text-white">Habilidades para entornos digitales</span>
                                </h2>
                                <p className="lead mb-4 text-grey">
                                    Desarrollo de conocimientos y aprendizajes aplicados a casos reales en UX
                                </p>                             
                            </div>
                        </div>
                    </div>

                    <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                        <div className="col-xxl-10">
                            <div className="row gx-5">
                                <div className="col-lg-6">
                                    <div className="itemCard itemCard-bg">
                                        <div>
                                            <img src="assets/imagen/skills/sk1.svg" alt="" className="wicon-100 mb-5 hoverEffect"/>
                                            <p className="lead fw-bold">Experiencias de usuario</p>
                                            <p className="text-grey">Entender, descubrir y mejorar la forma en que los usuarios interactúan con sus experiencias digitales.</p>
                                            {/* <a href="#" className="button-green">Ver más →</a> */}
                                        </div>    
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="itemCard itemCard-bg">
                                        <div>
                                            <img src="assets/imagen/skills/sk4.svg" alt="" className="wicon-100 mb-5 hoverEffect"/>
                                            <p className="lead fw-bold">Diseño de Productos</p>
                                            <p className="text-grey">Planificación y ejecución de estrategias de UX para mejorar la interacción y dar valor al producto.</p>
                                            {/* <a href="#" className="button-green">Ver más →</a> */}
                                        </div>    
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="itemCard itemCard-bg">
                                        <div>
                                            <img src="assets/imagen/skills/sk2.svg" alt="" className="wicon-100 mb-5 hoverEffect"/>
                                            <p className="lead fw-bold">Diseño de interfaces</p>
                                            <p className="text-grey">Creación de interfaces usables y atractivas, garantizando la interacción en entornos digitales.</p>
                                            {/* <a href="#" className="button-green">Ver más →</a> */}
                                        </div>    
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="itemCard itemCard-bg">
                                        <div>
                                            <img src="assets/imagen/skills/sk3.svg" alt="" className="wicon-100 mb-5 hoverEffect"/>
                                            <p className="lead fw-bold">Optimización de conversión</p>
                                            <p className="text-grey">Estrategias para aumentar la tasa de acciones deseadas por un usuario en una experiencia digital.</p>
                                            {/* <a href="#" className="button-green">Ver más →</a> */}
                                        </div>    
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="itemCard itemCard-bg">
                                        <div>
                                            <img src="assets/imagen/skills/sk5.svg" alt="" className="wicon-100 mb-5 hoverEffect"/>
                                            <p className="lead fw-bold">Consultoría en IA e Innovación <span className="labelProgreso">Pronto</span></p>
                                            <p className="text-grey">Realizando cursos de IA en UX y cursando el Máster en IA e Innovación en Founderz para optimizar proceso de IA en la investigación UX y creación de Productos Digitales.
 
                                            </p>
                                            {/* <a href="#" className="button-green">Ver más →</a> */}
                                        </div>    
                                    </div>
                                </div>

                            
                            </div>
                        </div>
                    </div>

                </div>
            </article>




        </div>
    )
}
export default Inicio