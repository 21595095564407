import React from 'react'
import { Link } from 'react-router-dom';


function Footer() {
    return (  
        <div>
            
        <footer className="py-4 mt-auto">
            <div className="container cFooter">
                <div className="row justify-content-between flex-column flex-sm-row">
                    <div className="col-lg-6 col-md-12 text-start">
                        <div className="boxCertification">
                            <div className="certification">
                                <img src="assets/imagen/icons/fcophox.png" alt="" className="iconAvatar hoverEffect"/>
                            </div>
                            <div className="information">
                                <p className="text-white fw-bold small m-0">Francisco</p>
                                <p className="text-grey fw-bold small m-0">UX Product Designer</p>
                                <p className="text-violete fw-bold small m-0">Trabajando 100% Remoto</p>
                            </div>
                            <div className="mb-5"></div>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 text-start">
                        <p className="fs-5 text-blue fw-bold small mb-5">Menú</p>
                        <div className="mb-2"></div>
                        <Link className="itemMenu mb-4 text-grey" to="/">Inicio</Link>
                        <Link className="itemMenu mb-4 text-grey" to="/estudios">Estudios</Link>
                        <div className="mb-5"><br/><br/></div>
                    </div>
                    <div className="col-lg-2 col-md-12 text-start">
                        <p className="fs-5 text-blue fw-bold small mb-5">Social</p>
                        <div className="mb-2"></div>
                        <a href="https://medium.com/@fcophox" target="_blank" rel="noreferrer" className="itemMenu mb-4 text-grey">Medium</a>
                        <a href="https://www.behance.net/fcophox" target="_blank" rel="noreferrer" className="itemMenu mb-4 text-grey">Behance</a>
                        <a href="https://www.linkedin.com/in/fcophox/" target="_blank" rel="noreferrer" className="itemMenu mb-4 text-grey">LinkedIn</a>
                        <div className="mb-5"><br/><br/></div>
                    </div>
                    <div className="col-lg-2 col-md-12 text-start">
                        <p className="fs-5 text-blue fw-bold small mb-5">Contacto</p>
                        <div className="mb-2"></div>
                        <a href="mailto:hi@fcophox.com " className="text-yellow" target="_blank"  rel="noreferrer">
                            <img src="assets/imagen/icons/envelope.svg" alt="" className="wicon-30"/>hi@fcophox.com
                        </a>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row align-items-center justify-content-between flex-column flex-sm-row">
                    <div className="col-lg-6 col-md-12 txtCenter">
                        <p className="small m-0 copyright text-lightblue"><img src="assets/imagen/icons/copylogo.svg" alt=""/>
                            <span className="text-lightblue">_fcoPhox - All rights pending - 2024</span>
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-12 text-end txtCenter">
                        <a className="iconRRSS" target="_blank"  rel="noreferrer" href="https://github.com/fcophox">
                            <img src="assets/imagen/icons/github.svg" alt=""/>
                        </a>
                        <a className="iconRRSS" target="_blank"  rel="noreferrer" href="https://www.linkedin.com/in/fcophox/">
                            <img src="assets/imagen/icons/linkedin.svg" alt=""/>
                        </a>
                        <a className="iconRRSS" target="_blank"  rel="noreferrer" href="https://www.instagram.com/fcophox.ux/">
                            <img src="assets/imagen/icons/instagram.svg" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    )
}
export default Footer