import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import Menu from "./components/Menu.js"

import Contact from "./components/Contact.js"
import ScrollToTop from './components/ScrollToTop'

import Footer from "./components/Footer.js"


// import MediumFeed from './components/MediumFeed.js'




import Inicio from "./pages/Inicio.js"
import Estudios from "./pages/Estudios.js"
import Metodologia from "./pages/Metodologia.js"
import Portafolio from "./pages/Portafolio.js"
import Entornos from "./pages/Entornos.js"

// import Casos from "./pages/Casos.js"

import Logistic from "./pages/casos/Logistic.js"



import Delivery from "./pages/casos/Delivery.js"
import Recruitment from "./pages/casos/Recruitment.js"
import Events from "./pages/casos/Events.js"
import Cyclists from "./pages/casos/Cyclists.js"
import Visualizer from "./pages/casos/Visualizer.js"
import Allergies from "./pages/casos/Allergies.js"






function App() {
  return (
    <div>
      <Router>
      <ScrollToTop/>
      <Menu/>

        <Routes>

          <Route exact path='/' element={<Inicio/>}/>
          <Route exact path='/estudios' element={<Estudios/>}/> 
          <Route exact path='/metodologia' element={<Metodologia/>}/> 
          <Route exact path='/portafolio' element={<Portafolio/>}/> 
          <Route exact path='/entornos' element={<Entornos/>}/> 

          
          

          {/* <Route exact path='/mindset' element={<Mindset/>}/>  */}

          {/* <Route exact path='/casos' element={<Casos/>}/> */}
          
          <Route exact path='/logistic' element={<Logistic/>}/>
          <Route exact path='/delivery' element={<Delivery/>}/>
          <Route exact path='/recruitment' element={<Recruitment/>}/>
          <Route exact path='/events' element={<Events/>}/>
          <Route exact path='/cyclists' element={<Cyclists/>}/>
          <Route exact path='/visualizer' element={<Visualizer/>}/>
          <Route exact path='/allergies' element={<Allergies/>}/>
        
        </Routes>
        <Contact/>
        <Footer/>

      </Router>

    </div>
  );
}

export default App;