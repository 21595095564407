import React from 'react'
// import { Link } from 'react-router-dom';


function Contact() {
    return (  
        <div>
        <article className="cta py-5">
            <div className="container py-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-xxl-10">
                        <div className="text-center my-5">
                            <h2 className="display-5 fw-bold">
                                <span className="text-white">¿Transformemos experiencias digitales?</span>
                            </h2>
                            <p className="lead mb-4 text-grey ">Da un paso hacia a la innovación en UX hoy y optimicemos las experiencias en tus productos.</p>         
                            <br/><br/>
                            <a href="mailto:hi@fcophox.com" className="button-green"  rel="noreferrer" target="_blank">Contactemos ➜</a>
                        </div>
                    </div>
                </div> 
            </div>
        </article>

        </div>
    )
}
export default Contact