import React from 'react'
import { Link } from 'react-router-dom';


function Events() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1 wow animate__animated animate__fadeIn">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Plataforma de Eventos</span>
                                    </h1>

                                    <div className="mb-5">
                                        <a href="https://www.behance.net/gallery/165977545/Interfaz-para-plataforma-de-eventos" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a>
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <img src="../assets/imagen/cases/events/events0.png" alt="" className="hoverEffect w-100 mb-4"/>
                                    <p className="lead mb-4 text-grey">
                                        Este proyecto se centró en el <span className="text-violete fw-bold">desarrollo de la interfaz de usuario (UI)</span>, una plataforma que permite a los usuarios descubrir y participar en eventos sociales. 
                                    </p>  
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-5">
                                            El enfoque principal fue <span className="fw-bold text-white">facilitar la conversión</span> de usuarios dentro de la aplicación, así como la compra de entradas y el canje de cupones, todo esto dispuesto para los usuarios con una actualización en la interfaz.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Objetivos del Proyecto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                            <li className="text-grey fs-5 mb-4">Diseñar una <span className="fw-bold text-white">interfaz intuitiva</span> que muestre eventos sociales con sus características.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Optimizar el proceso de conversión</span> de usuarios dentro de la aplicación.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Facilitar</span> la compra de entradas y el canje de cupones.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/events/events1.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

          
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                        Desarrollo de la Interfaz
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">

                                        <p className="lead text-white fw-bold">Investigación y Análisis</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Análisis de usuarios:</span> Investigación sobre las preferencias y comportamientos de los usuarios de la plataforma.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Benchmarking</span>: Estudio de interfaces de usuario similares en el mercado para identificar mejores prácticas.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Diseño de Interfaz</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Wireframes y prototipos:</span> Desarrollo de wireframes y prototipos para esbozar la estructura de la interfaz.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Diseño visual:</span> Creación de un diseño visual atractivo y funcional, en línea con la identidad de marca de Vesti.</li>
                                        </ul>

                                        <p className="lead text-white fw-bold">Flujos de Usuario</p>
                                        <ul className="mb-6">
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Desarrollo de flujos de usuario:</span> Diseño de flujos intuitivos desde el inicio de la plataforma.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold">Optimización de la conversión:</span> Implementación de estrategias de diseño que incentiven la conversión.</li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/events/events2.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/events/events3.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/events/events4.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/events/events5.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>

                                    {/* <img src="../assets/imagen/cases/events/events6.gif" alt="" className="w-100 bg-img mb-4"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/recruitment" target="_self" className="button-violete">← Plataforma de recutamiento</Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/cyclists" target="_self" className="button-violete">Comunidad de ciclismo →</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>



            </main>
        </div>
    )
}
export default Events