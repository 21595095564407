import React from 'react'
import { Link } from 'react-router-dom';


function Logistic() {
    return (  
        <div>
            <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Casos de estudio             
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Proceso RAG en Logística <img src="assets/imagen/icons/ia.svg" alt="" className=" wicon-30 mb-2"/></span>
                                    </h1>

                                    <div className="mb-5">
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver en Behance <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                        {/* <a href="#" target="_blank" className="text-dumdark fs-6 fw-semibold links mb-2">
                                            Ver Prototipo <img src="assets/imagen/icons/link.svg" alt="" className=" wicon-20 mr-6 mb-1"/> 
                                        </a> */}
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            
                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <img src="../assets/imagen/cases/logistic/logistic1.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <p className="lead mb-4 text-grey">
                                        Una empresa logística internacional maneja una gran cantidad de información relacionada con estados <span className="text-violete fw-bold">logísticos, facturas y datos de repartidores</span>. 
                                        Junto al área de innovación y tecnología, requieren de una solución con IA generativa.
                                    </p>   
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Contexto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <p className="text-grey fs-5 mb-5">
                                            Se crea una solución digital donde se sube la información logística de la empresa y se dispone por medio de una plataforma con inteligencia artificial. Esta solución corresponde a un <span className="fw-bold text-white">RAG o Retrieval-Augmented Generation.</span>
                                        </p>
                                        <p className="text-grey fs-5 mb-5">
                                            Esta plataforma con <span className="fw-bold text-white">Inteligencia Artificial</span> permitirá a los administradores y colaboradores consultar sobre productos específicos, obteniendo información sobre facturas, estados logísticos y visualización de PDFs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 text-center ">
                            <div className="col-sm-10 col-lg-12">
                                <p className="lead text-bg-dark fw-bolder text-green">
                                    Proceso RAG
                                </p>
                            </div>
                        </div>

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-3">
                                <div className="text-start my-5">
                                    <div href="#" target="_blank" className="itemCard itemCard-bg">
                                        <div>
                                            <img src="../assets/imagen/cases/logistic/rag1.svg" alt="" className="logoStudie"/>  <img src="../assets/imagen/cases/logistic/rag2.svg" alt="" className="logoStudie"/>  <img src="../assets/imagen/cases/logistic/rag3.svg" alt="" className="logoStudie"/> 
                                            <div className="mb-2"><br/></div>
                                            <p className="text-grey fw-bold">
                                                • Reportes<br/>
                                                • Facturas<br/>
                                                • Proveedores<br/>
                                            </p>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3">
                                <div className="text-start my-5">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <img src="../assets/imagen/cases/logistic/rag4.svg" alt="" className="logoStudie"/> 
                                                <div className="mb-2"><br/></div>
                                                <p className="text-grey fw-bold">
                                                División de <br/> contenido en pequeñas partes
                                                </p>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            <div className="col-xxl-3">
                                <div className="text-start my-5">
                                        <div href="#" target="_blank" className="itemCard itemCard-bg">
                                            <div>
                                                <img src="../assets/imagen/cases/logistic/rag5.svg" alt="" className="logoStudie"/> 
                                                <div className="mb-2"><br/></div>
                                                <p className="text-grey fw-bold">
                                                Prompt <br/>
                                                del <br/>
                                                solicitante
                                                </p>
                                            </div>    
                                        </div>
                                    </div>
                            </div>
                            <div className="col-xxl-3">
                                <div className="text-start my-5">
                                    <div href="#" target="_blank" className="itemCard itemCard-bg">
                                        <div>
                                            <img src="../assets/imagen/cases/logistic/rag6.svg" alt="" className="logoStudie"/> 
                                            <div className="mb-2"><br/></div>
                                            <p className="text-grey fw-bold">
                                                Información <br/>
                                                entregada para <br/>
                                                la revisión
                                            </p>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row gx-5 text-start ">
                            <div className="col-sm-12 col-lg-12">
                                <p className="text-bg-dark fw-bolder text-grey">
                                    <span className="fw-bold text-white">¿Qué hace un RAG?</span> <span className="fw-bold text-violete">RAG</span> o <span className="fw-bold text-violete">Retrieval-Augmented Generation</span> o <span className="fw-bold text-violete">Generación aumentada por recuperación</span>. Imagina que tienes una biblioteca enorme con todos los libros que puedas necesitar. 
                                    Cuando tienes una pregunta, no solo te gustaría que alguien la respondiera, sino que también busque en los libros para darte la mejor respuesta posible. 
                                    Eso es básicamente lo que hace un RAG.
                                </p>
                            </div>
                        </div>

                    </div>
                </article> 

                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Objetivos del proyecto
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                            <li className="text-grey fs-5 mb-4">Acceso <span className="fw-bold text-white">rápido y eficiente</span> a la información logística y de facturas.</li>
                                            <li className="text-grey fs-5 mb-4">Un <span className="fw-bold text-white">sistema centralizado</span>  y fácil de usar para gestionar y consultar datos.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Reducción del tiempo</span> empleado en la búsqueda y verificación de información.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="row gx-5">
                                    <div className="col-sm-12 col-lg-5">
                                        <p className="lead text-bg-dark fw-bolder text-green">
                                            Problemáticas a resolver con Inteligencia Artificial
                                        </p>
                                    </div>
                                    <div className="col-sm-12 col-lg-7">
                                        <ul>
                                            <li className="text-grey fs-5 mb-4">Innovar y ser evangelizadores en <span className="fw-bold text-white">soluciones digitales con IA</span> en la empresa.</li>
                                            <li className="text-grey fs-5 mb-4">Optimizar <span className="fw-bold text-white">procesos manuales y repetitivos</span> que consumen mucho tiempo.</li>
                                            <li className="text-grey fs-5 mb-4">Facilitar la búsqueda de <span className="fw-bold text-white">información específica de manera rápida</span>.</li>
                                            <li className="text-grey fs-5 mb-4"><span className="fw-bold text-white">Gobernabilidad</span> de información dispersa en múltiples sistemas y formatos digitales y manuales.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                    <p className="lead text-bg-dark fw-bolder text-green">
                                        Prototipo en Figma
                                    </p>
                                <div className="text-center my-5">
                                    <iframe className="prototipe"  src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FC15hMkk2ROQG4gKPzr8gJk%2FProducto-%253A%253A-Log%25C3%25ADstico%3Fpage-id%3D104%253A3890%26node-id%3D107-323%26viewport%3D577%252C614%252C0.06%26t%3D2rv1XaMgq3Y249XR-1%26scaling%3Dscale-down-width%26content-scaling%3Dfixed%26starting-point-node-id%3D107%253A323%26hotspot-hints%3D0" allowfullscreen></iframe>


                                   



                                </div>
                            </div>
                        </div>
                    </div>
                </article>


                {/* <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="../assets/imagen/cases/logistic/logistic1.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/logistic/logistic2.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/logistic/logistic3.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/logistic/logistic4.png" alt="" className="hoverEffect bg-img w-100 mb-4"/>
                                    <img src="../assets/imagen/cases/logistic/logistic5.gif" alt="" className="bg-img w-100 mb-4"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article> */}









                <article className="py-5 ">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-lg-6">
                                <div className="text-start my-5">
                                    <Link to="/allergies" target="_self" className="button-violete">← Plataforma de alergias</Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="text-end my-5">
                                    <Link to="/recruitment" target="_self" className="button-violete">Menú digital para pizzería →</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </article>


            </main>
        </div>
    )
}
export default Logistic