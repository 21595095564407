import React from 'react'
import { Link } from 'react-router-dom';

import ItemMethodology from './metodologia/ItemMethodology/ItemMethodology.js';


function Metodologia() {
    return (  
        <div>
             <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-10">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Sobre mi
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Metodología</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <article className="py-5">
                    <div className="container py-5">
                        <div className="row gx-5 justify-content-start">
                            <div className="col-xxl-10">
                                <div className="text-start my-5">
                                    <h2 className="display-5 fw-semibold">
                                        <span className="text-green">Proceso enfocado en el producto y sus diferentes estados</span>
                                    </h2>
                                    <p className="lead mb-4 text-grey">
                                        Este es mi proceso metodológico de desarrollo de producto digitales y optimización en UX, creado desde la experiencia, iteración y feedback en diferentes fases.
                                    </p>                             
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <article className="">
                    <div className="container">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp animate__delay-0s">
                            <div className="col-xxl-10">
                                <div className="text-center my-5">
                                    <img src="assets/imagen/methodology/metodologia.png" alt="" className="hoverEffect w-100 poster"/>
                                    <p className="text-bg-dark mb-4 fw-light">
                                       Creado para aplicar la <span className="text-violete">Metodología de Productos Digitales</span> y sus diferentes combinaciones y estados.
                                    </p>         
                                </div>

                            </div>
                        </div>
                    </div>
                </article>

                <article className="py-5">
                    <div className="container">
                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <ItemMethodology/>
                        </div>
                    </div>
                </article>      
            </main>
        </div>
    )
}
export default Metodologia