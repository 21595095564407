import React from 'react'
import { Link } from 'react-router-dom';

import ItemPortafolio from './portafolio/ItemPortafolio/ItemPortafolio.js';


function Portafolio() {
    return (  
        <div>
             <main className="flex-shrink-0">
                <header className="header-middle py-1">
                    <div className="container px-1">
                        <div className="row gx-1 align-items-center">
                            <div className="col-xxl-12">
                                <div className="text-start">
                                    <p className="text-grey fs-5 fw-semibold">
                                        <Link to="/" target="_self" className="breadcrumbs text-violete">
                                            <img src="assets/imagen/icons/home.svg" alt="" className=" wicon-30 mb-2"/> Inicio</Link> 
                                            <span className="lineal">—</span> 
                                            Sobre mi
                                    </p>
                                    <h1 className="display-1 text-white mb-5">
                                        <span className="text-white">Portafolio</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <article className="py-5">
                    <div className="container py-5">

                        <div className="row gx-5 justify-content-center wow animate__animated animate__fadeInUp">
                            <div className="col-xxl-10">

                                <article className="py-xl-6">
                                    <div className="overflow-hidden">
                                        <ItemPortafolio/>
                                    </div>
                                </article>

                            </div>
                        </div>

                    </div>
                </article>
           
            </main>
        </div>
    )
}
export default Portafolio